import React from 'react'

const Doc = (props) => {
    return (
        <div className="doc" onClick={props.click}>
            <div className="doc_img">
                <img src={props.img} alt="document" />
            </div>
            <div className="doc_text">{props.name}</div>
        </div>
    )
}
export default Doc;